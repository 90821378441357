@use "sass:math";
@use "sass:meta";

/* størrelser for misc cards */
$card-max-width: 34rem;
$card-max-width-desktop: 38rem;
$card-media-height: math.div($card-max-width, 1.5);
$card-media-height-desktop: math.div($card-max-width-desktop, 2);

@mixin card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  width: 100%;
  background-color: $neutral-bg-color;
}

@mixin card-media {
  width: 100%;
  height: $card-media-height;
  overflow: hidden;

  .image img {
    object-fit: cover; /* set image to cover available space, will be cropped if too big */
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }
}
