.icon-group {
  width: 100%;

  &--hide-overflow {
    overflow: hidden;
  }

  &__scroll-container {
    &--scroll-x {
      overflow-x: scroll;

      @media screen and (min-width: $break-large) {
        overflow-x: hidden;
      }
    }
  }
}

.icon-menu {
  display: flex;
  vertical-align: top;
  position: relative;
  width: 2.2rem;
  margin-left: 1.1rem;
  margin-right: 1.1rem;
  top: 1px;
  height: 2.4rem;
  cursor: pointer;
  transform-origin: center;
  box-sizing: border-box;

  &__bar {
    position: absolute;
    height: 1px;
    width: 100%;
    border-radius: 1px;
    border: 1px solid #00000040;
    background-color: black;
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: 5px;
      right: 0;
      width: 70%;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 5px;
      width: 100%;
    }
  }

  &--active {
    .icon-menu__bar {
      &:nth-child(1) {
        opacity: 0;
      }

      &:nth-child(2) {
        transform: translateY(-50%) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(50%) rotate(-45deg);
        bottom: 50%;
      }
    }
  }
}
