.icon-group-locations {
  flex-basis: calc(100% - 2.4rem);

  @media screen and (min-width: $break-small) {
    flex-basis: calc(50% - 2.4rem);
    min-width: auto;
  }

  &--three-columns {
    @media screen and (min-width: $break-large) {
      flex-basis: calc(33.333% - 2.4rem);
      margin-right: 1.2rem;
    }
  }
}
