$os-bottom-nav-bar: calc(env(safe-area-inset-bottom, 2.125rem)); /* subtract height of os spesific bottom navigation bar with fallback of 34 px */
$left-right-inset: $padding * 2;

.filter-content {
  position: fixed;
  z-index: $z-index-a;
  background-color: $white;
  width: 100%;
  padding: $left-right-inset $left-right-inset 0 $left-right-inset;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow-y: auto;
  box-sizing: border-box;
  min-width: 27.2rem;
  top: 0;
  bottom: auto;
  max-height: calc(100dvh - $os-bottom-nav-bar);

  // Old max-height for larger screens.
  max-height: calc(
    100vh - $header-height - $top-inset - $filter-heading-height - $filter-buttons-height - $os-bottom-nav-bar
  );

  // Adjust max-height for mobile screens.
  @media screen and (max-width: $break-small - 1) {
    top: 0;             
    bottom: auto;       
    max-height: calc(100dvh - $os-bottom-nav-bar);  
  }

  @media screen and (min-width: $break-small) {
    width: calc(100% - $padding * 4);
    height: auto;
  }

  @media screen and (min-width: $break-large) {
    position: absolute;
    width: 100%;
  }

  &__buttons {
    position: sticky;
    bottom: 0;
    display: flex;
    width: auto;
    background-color: $white;
    padding: $padding * 1.5 $left-right-inset $padding * 1.5 $left-right-inset;
    margin-left: -$left-right-inset;
    margin-right: -$left-right-inset;
    border-top: 1px solid #efefef; /* TEMP - inntil Ane får sett på det */
    border-bottom: 1px solid #efefef;
  }

  &-container {
    position: relative;
    width: 100%;

    @media screen and (min-width: $break-small) {
      width: calc(100% - $padding * 4);
    }

    @media screen and (min-width: $break-large) {
      width: 100%;
    }
  }
}
