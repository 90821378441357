.search-hit {
  display: contents;
  
  a{
    display: block;

    @media screen and (min-width: $break-large) {
      &:hover {
        .search-hit--title {
          font-weight: bold;
          color: $blue-bg-color;
        }

        background-color: $blue-light;
      }
    }

    &:focus-visible {
      outline: none;
      background-color: $blue-light;

      .search-hit--title {
        font-weight: bold;
        color: $blue-bg-color;
      }
    }

    &:focus {
      outline: none;
      background-color: $blue-light;

      .search-hit--title {
        font-weight: bold;
        color: $blue-bg-color;
      }
    }
  }
}
