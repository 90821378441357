.search-box {
  input {
    font-size: $font-size;
    border: 1px solid $gray-light;
    border-radius: $border-radius;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  input:focus {
    outline: none;
  }

  &--input-adornment {
    position: absolute;
    top: 1rem;
    z-index: $z-index-e;

    &--left {
      left: 0.8rem;
    }

    &--right {
      background-color: transparent;
      cursor: pointer;
      right: 5.4rem;
    }

    &--right-right {
      background-color: transparent;
      cursor: pointer;
      right: 0.8rem;
      padding-left: 0.8rem;
      padding-right: 0.4rem;
      border-left: 1px solid $black;
      color: $gray-dark;
    }
  }
}
