.filter-button-container {
  display: flex;
  overflow: scroll hidden;
  width: 100%;
  height: $filter-buttons-height; /* need to record height of this to calculate height of filter content dropdown */

  &--padding {
    padding-left: $padding * 2;
    padding-right: $padding * 2;
  }

  /* hide scrollbars in Chrome, Edge, Opera, Safari, and other WebKit-based browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: $break-medium) {
    overflow-x: visible;
  }

  @media screen and (min-width: $break-large) {
    padding-left: 0;
    padding-right: 0;
  }
}
