@use "sass:math";
@use "sass:meta";

/* adjust javascript enum ResponsiveBreakpoints in styling-utils.ts to these */
$small: 60;
$medium: 76.8;
$large: 102.4;
$x-large: 128;

/* this function will convert rem to em values (we need to use ems for responsive break-points) */
@function em($rem, $context: $browser-context) {
  @return is-number($rem) and is-number($context) and math.div($rem, $context) * 10em; /* multiply by 10 because of html font-size: 62.5% */
}

@function is-number($value) {
  @return meta.type-of($value) == "number";
}

/* font-size: 100% defaults to 16px root font size in most browsers */
$browser-context: 16;

/* we keep everything in steps of 4 */
$step: 0.4rem;

/* content max width (for desktop content) */
$content-max-width: calc(#{$x-large}rem); /* 128rem / 1280px; */

$content-container-width-xx-small: 34rem;
$content-container-width-x-small: 40rem;
$content-container-width-small: calc(#{$small}rem); /* 60rem */
$content-container-width-medium: calc(#{$medium}rem); /* 76.8rem */
$content-container-width-large: calc(#{$large}rem); /* 102.4rem */
$content-container-width-x-large: calc(#{$x-large}rem); /* 128rem */

/* default min widths for misc content items */
$content-item-min-width: 28rem;
$content-item-min-width-small: 34rem;
$content-item-min-width-large: 40rem;


/* default max width for misc content items on large screens */
$content-item-max-width-large: calc(#{$small}rem); /* 60rem */

/* responsive breakpoints */

/* from mobile to tablet */
$break-small: em($small); /* 60rem / 600px / 37.5em */
$break-medium: em($medium); /* 76.8rem / 768px / 48em */

/* from tablet to desktop */
$break-large: em($large); /* 102.4rem / 1024px / 64em */
$break-x-large: em($x-large); /* 128rem / 1280px / 80em */

/* colors */
$green: #74e672;
$green-lighter: #bdfcbc;
$green-darker: #35a133;
$blue: #2dd9ff;
$blue-light: #dff9ff;
$blue-lighter: #adf0ff;
$yellow: #ffd966;
$fill-color: #fcfcfc;
$black: #000;
$blackish: #2d2d2d;
$white: #fff;
$gray-darkest: #373737;
$gray-darker: #404040;
$gray-dark: #646464;
$gray-light: #e7e7e7;
$gray-lightest: #eee;
$red: #c60000;
$red-dark: #B10A0E;
$icon-color: #000;
$blue-bg-color: #004a69;
$gray-bg-color: #fbfbfb;
$green-bg-color: #e9ffe8;
$neutral-bg-color: #f9f9f9;
$yellow-bg-color: #fff9e6;
$disabled-bg-color: #e5e5e5;
$beige-bg-color: #DDD1C1;

/* global padding for main layout elements */
$padding: 1.2rem;
$left-and-right-inset: $padding * 2;
$top-inset: $padding * 2;

/* height of header element */
$header-height: 7.2rem;

/* min-height of footer element */
$footer-min-height: 10rem;

/* width of sidebar (only visible in desktop mode) */
$sidebar-width: 28rem;

/* default border color */
$border-color: $gray-darker;

/* default box shadow */
$box-shadow: 0 1px 3px 0 rgba($black, 0.1);

/* font family and size */
$font-size: 1.6rem;
$font-size-small: 1.4rem;
$font-size-large: 2rem;
$font-size-x-large: 2.4rem;
$font-size-xx-large: 3.6rem;
$button-font-size: 1.6rem;
$line-height: 1.5;

/* height, padding, border-radius of input elements */
$border-radius: 4px;

/* touch target for clickable items needs to be at least 44px */
$touch-target-size: 4.4rem;

/* minste størrelse for små ikoner */
$icon-small-min-size: 2.8rem;

/* height of misc filter elements */
$filter-button-height: calc($touch-target-size + 4px); /* add a little somethin' for box-shadow to show */
$filter-heading-height: 6.4rem;
$filter-buttons-height: 6rem;
$filter-button-spacer: calc($filter-buttons-height - $filter-button-height);

/* margin for block-elementer */
$block-margin: $left-and-right-inset;

@mixin block-margin {
  margin: math.div($block-margin, 2) $block-margin;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: $break-large) {
    margin: $block-margin;
    margin-bottom: 0;
    margin-top: 0;
  }
}

/* do not delete any code from this, commented out or not ! */
@mixin hidden-class {
  white-space: nowrap; /* workaround for falsely pronounced, smushed text */
  width: 1px; /* set it to the smallest possible size (some screen readers ignore elements with zero height and width) */
  height: 1px;
  overflow: hidden; /* hide overflowing content after resizing */
  border: 0; /* reset any property that may change the elements size */
  padding: 0;

  /* clipping defines what part of an element should be displayed */

  /* clip: rect(1px 1px 1px 1px); /* deprecated clip property for older browsers > IE6, IE7 */

  /* clip: rect(1px, 1px, 1px, 1px); */
  clip-path: inset(
    50%
  ); /* clip-path for newer browsers. inset(50%) defines an inset rectangle that makes the content disappear */

  /* margin: -1px; // it seems like at the moment nobody is quite sure why margin: -1px is there. On top of that it seems to cause issues (see: https://github.com/h5bp/html5-boilerplate/issues/1985) */
  flex: unset;
}

@mixin sticky-top {
  position: sticky;
  background-color: $white;
  z-index: $z-index-b;
  top: $header-height;
}

@mixin unordered-list-with-green-checks {
  list-style-type: none;
  margin-bottom: $padding;

  li {
    position: relative;
    padding-left: $padding * 2;
    padding-bottom: $step;

    /* checkmarks on list items */
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: -0.8rem;
      top: 0;
      transform: rotate(45deg);
      height: 1.6rem;
      width: 0.8rem;
      border-bottom: 0.2rem solid;
      border-right: 0.2rem solid;
      color: $green-darker;
    }
  }
}

@mixin unordered-list-row-with-green-checks-on-top {
  list-style-type: none;
  margin-bottom: $padding;
  display: flex;
  margin-top: 4rem;
  margin-left: 0;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;

  li {
    position: relative;
    padding-left: 0;
    padding-bottom: 0;
    margin: 0 $padding * 2;
    min-width: 8rem;
    min-height: 8rem;
    display: flex;
    justify-content: center;

    /* checkmarks on list items */
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      left: 50%;
      top: -4rem;
      transform: rotate(45deg);
      height: 3.2rem;
      width: 1.6rem;
      border-bottom: 0.4rem solid;
      border-right: 0.4rem solid;
      color: $green-darker;
    }
  }
}

@mixin text-style {
  text-align: left; /* left er default */
  font-size: $font-size;

  &__align {
    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  &__color {
    &--dark {
      color: $gray-darkest;
    }

    &--gray {
      color: $gray-dark;
    }

    &--red {
      color: $red;
    }

    &--light {
      color: $white;
    }

    &--green {
      color: $green-darker;
    }
  }

  &__size {
    &--small {
      font-size: $font-size-small;
    }

    &--normal {
      font-size: $font-size;
    }

    &--large {
      font-size: $font-size-large;
    }

    &--x-large {
      font-size: $font-size-x-large;
    }

    &--xx-large {
      font-size: $font-size-xx-large;
    }
  }

  &__style {
    &--italic {
      font-style: italic;
    }
  }

  &__weight {
    &--black {
      font-weight: 900;
    }

    &--extra-bold {
      font-weight: 800;
    }

    &--bold {
      font-weight: bold;
    }

    &--normal {
      font-weight: normal;
    }
  }

  &--no-wrap {
    white-space: nowrap;
  }

  &--shadow {
    text-shadow: 0 1px 35px rgb(0 0 0 / 80%);
  }

  &--truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &--underline {
    text-decoration: underline;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--gradient {
    mask-image: linear-gradient(to top, transparent, white 100%);
    height: 1rem;
  }

  &__backgroundColor {
    &--white {
      background-color: $white;
    }

    &--light-blue {
      background-color: $blue-light;
    }

    &--gray {
      background-color: $gray-bg-color;
    }
  }
}

@mixin span-component-full-width($adjust-margin: true) {
  width: calc(100% + $left-and-right-inset * 2);

  @if $adjust-margin {
    margin-right: -$left-and-right-inset;
    margin-left: -$left-and-right-inset;
  }
}

/* z-index */
$z-index-a: 10;
$z-index-b: 20;
$z-index-c: 30;
$z-index-d: 40;
$z-index-e: 50;
$z-index-f: 60;


