.VisitsGraph {
  box-shadow: 0 0 3px 0 #f8f8f8;
  border-radius: 1rem;
  background-color: #f8f8f8;
  padding: 2rem;

  .responsive-container {
    width: calc($content-container-width-x-small - 4rem);
    overflow: scroll hidden;
    -webkit-overflow-scrolling: touch;
  }

  .recharts-label {
    fill: $white;
  }
}
