$border-width: 1px;
$title-padding: $step * 2;
$triangle-size: 9.6rem;
$max-width: 44rem;
$max-width-x-large: 40rem;
$margin-large: 2rem;
$notification-height: 2.8rem;
$campaign-icon-width: 6.2rem;
$campaign-alignment-adjustment: 1rem;

.register-button {
  max-width: $max-width;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 4rem;

  &--block-margin {
    @include block-margin;
  }

  @media screen and (min-width: $break-small) {
    margin: 0 4.8rem 4rem;
  }

  @media screen and (min-width: $break-large) {
    margin: 0 $margin-large 4rem;
  }

  &--is-odd-number {
    @media screen and (min-width: $break-x-large) {
      margin-left: 0;
      margin-right: 4rem;
      max-width: $max-width-x-large;
    }

    &:last-child {
      @media screen and (min-width: $break-large) {
        margin-right: $max-width + $margin-large * 3; /* skyver siste boks så den legger seg til venstre hvis den er ensom på slutten */
      }

      @media screen and (min-width: $break-x-large) {
        margin-right: 0;
      }
    }
  }

  &--is-only-one {
    @media screen and (min-width: $break-x-large) {
      width: 100%;
      margin-right: 0;
    }

    &:last-child {
      @media screen and (min-width: $break-large) {
        margin-right: $margin-large;
        margin-right: 0;
      }
    }
  }

  &--slider {
    margin: 0;
    max-width: none;
  }

  &__inner {
    box-shadow: $box-shadow;
    background-color: $fill-color;
    cursor: default;
    border-radius: $border-radius;
    min-height: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    width: 100%;
    color: $black;

    &--fixed-content-height {
      min-height: 40rem;

      &--slider {
        min-height: 52.8rem;
      }
    }

    &__content {
      width: 100%;
      padding: 0 $padding;
    }

    &__info {
      display: none;
      width: 100%;
      padding: 0 $padding;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      color: $black;

      @media screen and (min-width: $break-large) {
        display: flex;
        justify-content: space-between;
      }

      ul {
        @include unordered-list-with-green-checks;

        padding-left: $padding * 4;
        margin-bottom: 0;
      }

      &--open {
        display: flex;
      }

      > * {
        max-width: 36rem;
      }
    }

    &__title {
      background-color: $gray-darker;
      padding: $title-padding;
      color: #fff;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: $touch-target-size + $step;
      border-radius: $border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .heading {
        color: inherit;
      }

      h2 {
        margin-bottom: 0;
      }

      &__campaign {
        background-color: transparent;
        position: absolute;
        right: $campaign-alignment-adjustment;
        width: $campaign-icon-width;
        content: url("/images/components/campaign.png");
      }
    }
  }

  &__notification {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    width: 100%;
    padding-bottom: 0.8rem;
    height: $notification-height;

    &__line {
      display: block;
      width: 100%;
      border-top: 1px solid $gray-light;

      &:first-of-type {
        margin-right: 2.4rem;
      }

      &:last-of-type {
        margin-left: 2.4rem;
      }
    }

    &__spacer {
      height: $notification-height;
    }
  }
}
