.boundary {
  position: relative;
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: dashed;
  padding: 1rem;
  color: black;
  display: contents; /* @Jonatan toggle this to show/hide boundary styling */

  &__inner {
    display: flex;
    position: absolute;
    top: -0.625rem;
    left: 1rem;
    column-gap: 0.25rem;
    letter-spacing: 0.1em;
    line-height: 1rem;
    text-transform: uppercase;

    @media (width >= 1024px) {
      left: 2.25rem;
    }

    &__label {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      border-radius: 99px;
      color: black;
      background-color: white;
    }
  }
}
