.markdown {
  text-align: left;

  &--horizontal-placement {
    &--center {
      text-align: center;

      /* need to center ul and ol specifically */
      ul,
      ol {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &--text-theme {
    &--dark {
      color: $black;
    }

    &--light {
      color: $white;
    }
  }

  &--width {
    &--full {
      width: 100%;
    }
  }

  a,
  li {
    color: unset;
  }
}
