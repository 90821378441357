.opening-hours-card {
  display: flex;
  flex-flow: wrap;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: $gray-bg-color;

  @media screen and (min-width: $break-small) {
    justify-content: center;
  }

  &__block {
    width: $content-item-min-width-small;
    margin: $left-and-right-inset;

    @media screen and (min-width: $break-small) {
      margin: $left-and-right-inset $left-and-right-inset * 2;
    }

    @media screen and (min-width: $break-large) {
      margin: $left-and-right-inset $left-and-right-inset * 4;
    }
  }

  &__image {
    display: none;

    @media screen and (min-width: $break-large) {
      display: flex;
      width: 100%;
    }

    .opening-hours-card__block {
      @media screen and (min-width: $break-x-large) {
        width: $content-item-min-width-large;
      }
    }
  }
}
