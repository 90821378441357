.share-buttons {
  display: flex;

  &--primary > * {
    margin: 0 0.8rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
