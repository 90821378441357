@use "sass:math";
@use "sass:meta";

$card-height: 44rem;

.article-card {
  @include card;

  &__media {
    @include card-media;
  }

  &__content {
    padding: $padding $padding * 1.5 $padding * 1.5;
  }
}
