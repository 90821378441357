.video-player {
  height: 100%;
  position: relative;
  width: 100%;

  video {
    object-fit: contain;
  }

  &__icons {
    z-index: $z-index-a;
    position: absolute;
    right: 1.2rem;
    top: 1.2rem;
  }
}
