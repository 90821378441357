.move-up-or-down-container {
  &--move-down {
    position: absolute;
    bottom: -$touch-target-size;
  }

  &--move-up {
    position: absolute;
    top: -$touch-target-size;
  }
}
