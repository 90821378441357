@use "sass:math";

.value-card {
  width: 100%;
  box-shadow: $box-shadow;
  border-radius: math.div($border-radius, 3);
  min-width: $content-item-min-width;
  display: flex;
  flex-direction: column;

  &--clickable {
    cursor: pointer;
  }

  &--selected {
    background-color: $green;
  }

  &--expired {
    background-color: $gray-light;
    cursor: default;
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: $booking-button-padding * 2;
    border-bottom: 1px solid $gray-light;
  }

  &__number-row {
    width: 100%;
    height: $touch-target-size - $step;
    display: flex;

    &__klipp {
      width: 9.8%;
      padding: $booking-button-padding;

      &:last-child {
        width: 11.8%;
      }

      &--marked {
        background-color: $gray-lightest;
        position: relative;

        &--selected {
          background-color: $green-lighter;
        }

        .icon {
          position: absolute;
          inset: 0;
        }
      }

      &--unmarked {
        color: $gray-dark;

        &--selected {
          background-color: $white;
        }
      }
    }
  }
}
