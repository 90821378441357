@use "sass:math";

.filter-button-small {
  min-width: 6rem; /* bredden her må tilpasses break-point og filterraden over */

  .filter-button-small__button {
    min-height: $touch-target-size;
    width: 100%;
    position: relative;

    &__visible {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: math.div($border-radius, 2);
      min-height: calc($touch-target-size - 0.8rem);
      font-size: $font-size-small;
      padding: 0.4rem;
      text-wrap: nowrap;
      color: $black;
      border-bottom: 2px solid #ddd;

      @media screen and (min-width: $break-large) {
        padding: 0.4rem;
      }
    }
  }

  &--dark {
    .filter-button-small__button__visible {
      color: $white;
      border-bottom: 2px solid $black;
    }
  }

  &--selected {
    .filter-button-small__button__visible {
      background-color: $blue-bg-color;
      color: $white;
    }

    &.filter-button-small--dark {
      .filter-button-small__button__visible {
        background-color: $white;
        color: $black;
      }
    }
  }
}
