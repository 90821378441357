.chip {
  padding: 0.4rem 1.8rem;
  border-radius: $border-radius;
  background-color: $gray-bg-color;

  &--selected {
    background-color: $blue-light;
  }

  &__background-color {
    &--white {
      background-color: $white;
    }

    &--light-blue {
      background-color: $blue-light;
    }

    &--gray {
      background-color: $gray-bg-color;
    }
  }
}
