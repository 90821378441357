@mixin border-radius-mobile {
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
}

@mixin border-radius-desktop {
  border-top-right-radius: 0;
  border-bottom-left-radius: $border-radius;
}

.select-center-button {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  width: 100%;
  margin-bottom: 3.2rem;
  background-color: $fill-color;

  @media screen and (min-width: $break-large) {
    flex-direction: row;
    height: 20rem; /* fast høyde på desktop */
  }

  & > a {
    display: contents;
  }

  /* fast høyde på bildet 
    > tilnærmet 2:3 høyde:bredde på break-points default (< 600px), small (>= 600px), medium (>= 768px) og large (>= 1024px) */
  .image {
    @include border-radius-mobile;

    width: 100%;
    height: 22rem;

    @media screen and (min-width: $break-small) {
      height: 36rem;
    }

    @media screen and (min-width: $break-medium) {
      height: 48rem;
    }

    @media screen and (min-width: $break-large) {
      @include border-radius-desktop;

      width: 33%;
      height: 100%;
    }

    img {
      @include border-radius-mobile;

      @media screen and (min-width: $break-large) {
        @include border-radius-desktop;
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: $padding $padding * 2 $padding * 1.5;
    align-items: center;

    @media screen and (min-width: $break-large) {
      width: 67%;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: $padding * 2;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      @media screen and (min-width: $break-large) {
        align-items: flex-start;
        justify-content: space-between;
      }

      &__description {
        display: none;

        @media screen and (min-width: $break-large) {
          display: flex;
          flex-direction: column;
        }
      }

      &__facilities {
        display: none;

        @media screen and (min-width: $break-large) {
          padding: $padding * 2 0;
          display: flex;
          flex-flow: wrap;
        }
      }
    }

    &__button {
      @media screen and (min-width: $break-large) {
        position: absolute;
        right: $padding;
      }
    }
  }
}
