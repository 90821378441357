.gift-card-amount {
  &__button {
    &:hover {
      background-color: $blue-light;
    }

    &--selected {
      font-weight: bold;
      color: $blue-bg-color;
      background-color: rgba($blue-light, 0.7);
    }
  }
}
