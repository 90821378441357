.footer-content {
  padding: $padding * 2;
  max-width: $content-max-width;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: wrap;

  @media screen and (min-width: $break-large) {
    padding: $padding * 2 $padding;
  }

  @media screen and (min-width: $break-x-large) {
    padding: $padding * 2 0;
  }

  &__item {
    width: 100%;
    max-width: 20rem;
    padding-right: $padding;

    &--placeholder {
      max-width: 34rem;
    }

    h3 {
      font-size: $font-size;
    }

    .link {
      color: #fff;
      text-decoration: none;
    }
  }
}
