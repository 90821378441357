.alert-bubble {
  border-radius: 50%;
  background-color: $green;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;
  font-size: 1.2rem;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  right: 0;
  font-weight: bold;

  &--raised {
    top: -0.5rem;
  }

  &__size {
    &--x-small {
      height: 1.6rem;
      width: 1.6rem;
      font-size: 1rem;
    }

    &--small {
      height: 2rem;
      width: 2rem;
      font-size: 1.2rem;
    }

    &--medium {
      height: 2.4rem;
      width: 2.4rem;
      font-size: 1.4rem;
    }

    &--large {
      height: 3.2rem;
      width: 3.2rem;
      font-size: 1.6rem;
    }

    &--x-large {
      height: 4.4rem;
      width: 4.4rem;
      font-size: 1.8rem;
    }

    &--xx-large {
      height: 6.4rem;
      width: 6.4rem;
      font-size: 2rem;
    }
  }
}
