.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;

  &--gradient {
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25%;
      background: linear-gradient(to top, $gray-bg-color, rgba($gray-bg-color, 0));
      pointer-events: none;
    }
  }
}
