$button-width: 3.5rem;
$button-height: 3.5rem;
$button-expanded-width: 12rem;
$button-radius: 50%;
$button-expanded-radius: 30px;
$animation-duration: 2s;
$animation-delay: 2s;

.play-pause-button {
  &__wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $black;
    border-radius: $button-radius;
    width: $button-width;
    height: $button-height;
    transition:
      width 0.5s ease,
      border-radius 0.5s ease;
    animation: play-pause-button-expand-collapse $animation-duration ease backwards;
    animation-delay: $animation-delay;

    @media (min-width: $break-large) {
      &:hover {
        width: $button-expanded-width;
        border-radius: $button-expanded-radius;
      }
    }
  }

  &__text {
    opacity: 0;
    color: $white;
    white-space: nowrap;
    overflow: hidden;
    max-width: 0;
    transition:
      opacity 0.3s ease,
      max-width 0.5s ease;
    animation: play-pause-button-expand-collapse-text $animation-duration ease backwards;
    animation-delay: $animation-delay;

    @media (min-width: $break-large) {
      .play-pause-button__wrapper:hover & {
        opacity: 1;
        margin-left: 0.5rem;
        max-width: 10rem;
      }
    }
  }
}

@keyframes play-pause-button-expand-collapse {
  0% {
    width: $button-width;
    border-radius: $button-radius;
  }

  50% {
    width: $button-expanded-width;
    border-radius: $button-expanded-radius;
  }

  100% {
    width: $button-width;
    border-radius: $button-radius;
  }
}

@keyframes play-pause-button-expand-collapse-text {
  0% {
    max-width: 0;
    opacity: 0;
  }

  50% {
    max-width: 10rem;
    margin-left: 0.5rem;
    opacity: 1;
  }

  100% {
    max-width: 0;
    opacity: 0;
  }
}
