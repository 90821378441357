.horizontal-container {
  display: flex;
  flex-flow: wrap;
  position: relative;

  &__horizontal-placement {
    &--left {
      justify-content: flex-start;
      text-align: left;
    }

    &--center {
      justify-content: center;
      text-align: center;
    }

    &--right {
      justify-content: flex-end;
      text-align: right;
    }

    &--space {
      justify-content: space-between;
    }
  }

  &__vertical-placement {
    &--top {
      align-items: flex-start;
    }

    &--center {
      align-items: center;
    }

    &--bottom {
      align-items: flex-end;
    }
  }

  &__height {
    &--full {
      height: 100%;
    }
  }

  &__width {
    &--full {
      width: 100%;
    }
  }

  &--no-wrap {
    flex-flow: nowrap;
  }
}
