.full-width-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include span-component-full-width;

  @media screen and (min-width: $break-large) {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  &--span-top-margin {
    margin-top: -$top-inset;
  }

  &--padding-on-mobile {
    padding: 0 $left-and-right-inset;

    @media screen and (min-width: $break-large) {
      padding: 0;
    }
  }

  &--background-color {
    padding: $left-and-right-inset;

    &--transparent {
      background-color: transparent;
    }

    &--dark {
      background-color: $blue-bg-color;
    }
  }
}
