@use "sass:math";
@use "sass:meta";

$input-height: 4.4rem;
$input-padding: 0.8rem;
$input-border-radius: 4px;
$input-size-small: 8rem;

/* styling input and select elements */
@mixin input {
    font-family: inherit; /* override browser specific fonts */
    font-size: $font-size; /* input size has to be big enough to prevent zooming */
    height: $input-height;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    background-color: $white;
    color: $black;
    max-width: $content-item-max-width-large;
    box-sizing: border-box;
    stroke: $gray-darkest;
    filter: drop-shadow(0 1px 3px rgba($black, 0.1));
    padding: 1.5rem;

    &::placeholder {
      color: $gray-dark;
    }
  }
  
  @mixin input-width {
    min-width: $content-item-min-width;
    width: 100%;
  
    @media screen and (min-width: $break-small) {
      min-width: $content-item-min-width-small;
    }
  
    @media screen and (min-width: $break-large) {
      min-width: $content-item-min-width-large;
    }
  }
  
  
  /* focus style of input and select elements */
  @mixin input-focus-style {
    outline-offset: 0;
    outline: $black auto 1px;
  }
  
  @mixin input-disabled-or-read-only {
    background-color: $disabled-bg-color;
    border-color: #ccc;
    color: $gray-darkest;
  }