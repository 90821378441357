@use "sass:math";

.activity-card {
  @include card;

  &--placeholder {
    box-shadow: none;
    height: 0;
  }

  &__media {
    @include card-media;
  }

  &__heading {
    width: 100%;
    display: flex;
    padding: math.div($padding * 2, 3) $padding $padding;
    height: $touch-target-size;
  }
}
