@use "sass:math";

.input-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .input-adornment {
    position: absolute;
    right: 0.4rem;
    top: math.div($input-height - $icon-small-min-size, 2);
    cursor: pointer;
  }
}

.input {
  @include input;
  @include input-width;

  &:disabled,
  &:read-only {
    @include input-disabled-or-read-only;
  }

  &:focus-visible {
    @include input-focus-style;
  }

  &__size {
    &--small {
      width: $input-size-small;
      min-width: auto;
    }
  }

  &__weight {
    &--normal {
      font-weight: normal;
    }
    
    &--bold {
      font-weight: bold;
    }
  }
}

.checkbox-and-radio {
  display: flex;
  align-items: center;
  accent-color: $blue-bg-color;

  &__input {
    cursor: pointer;
    border: 1px solid $border-color; /* TODO: styling virker ikke */
    height: 2.8rem;
    width: 2.8rem;
    min-height: 2.8rem;
    min-width: 2.8rem;
    margin-right: $step * 3;
    border-radius: $input-border-radius;

    &:focus-visible {
      @include input-focus-style;
    }
  }

  &__label {
    cursor: pointer;
    font-size: $font-size-small;
    text-align: left;
  }
}

.input-error-message {
  color: $red;
  width: 100%;
  min-width: $content-item-min-width;
  max-width: $content-item-max-width-large;

  @media screen and (min-width: $break-small) {
    min-width: auto;
  }

  &__size {
    &--small {
      width: $input-size-small;
      min-width: auto;
    }
  }
}
