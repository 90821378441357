.horizontal-spacer {
  padding-bottom: 1.6rem; /* small is default */

  &__size {
    &--xx-small {
      padding-bottom: 0.4rem;
    }

    &--x-small {
      padding-bottom: 0.8rem;
    }

    &--small {
      padding-bottom: 1.6rem; /* default */
    }

    &--medium {
      padding-bottom: 2.4rem;
    }

    &--large {
      padding-bottom: 4rem;
    }

    &--x-large {
      padding-bottom: 6.4rem;
    }

    &--xx-large {
      padding-bottom: 8rem;
    }

    &--line-height {
      padding-bottom: $font-size * $line-height;

      &-small {
        padding-bottom: $font-size-small * $line-height;
      }
    }
  }
}
