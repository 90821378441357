@use "sass:math";

.notice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;
  height: fit-content;
  border-radius: math.div($border-radius, 2);

  &--information {
    background-color: rgba($blue, 0.2);
  }

  &--success {
    background-color: rgba($green, 0.2);
  }

  &--error {
    background-color: rgba($red, 0.2);
  }

  &--warning {
    background-color: rgba($yellow, 0.2);
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: $content-container-width-x-small;
    padding-bottom: 0.8rem;

    @media screen and (min-width: $break-large) {
      flex-direction: row;
      max-width: $content-container-width-medium;
      align-items: center;
      gap: 2rem;
      padding-bottom: 0;
    }
  }
}
