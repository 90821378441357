.icon-button {
  border-radius: $border-radius * 1.5;

  &--primary {
    background-color: $blackish;

    .icon > * {
      color: $white;
    }
  }

  .icon > * {
    width: 2.8rem;
    height: 2.8rem;
  }

  .icon.google-calendar-icon > * {
    width: 3.6rem;
    height: 3.6rem;
  }
}
