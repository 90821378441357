.label {
  font-size: $font-size-small;
  margin-bottom: 0.4rem;
  display: flex;
  align-items: center;
  color: #333;

  &--hidden {
    @include hidden-class;

    position: fixed; /* remove the item from normal flow */
  }

  &__weight {
    &--bold {
      font-weight: bold;
    }
  }
}
