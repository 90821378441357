.author {
  &__underline {
    width: 50%;
    height: 1px;
    background-color: $gray-darkest;
  }

  &__link {
    .text {
      &:hover {
        color: $blue-bg-color;
        font-weight: bold;
      }
    }
  }
}
