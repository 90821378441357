.favorite-instructor {
  box-shadow: 0 0 3px 0 #f8f8f8;
  padding: 3rem;
  border-radius: 4rem;
  background-color: $blue-light;
  min-width: $content-container-width-x-small;
  max-height: 20rem;

  .instructor-image {
    width: 30%;

    img {
      border-radius: 4px;
    }
  }
}
