$price-interval-width: 7rem;

.price {
  white-space: nowrap;

  &__color {
    &--primary {
      color: $green-darker;
    }

    &--red {
      color: $red-dark;
    }
  }

  &__size {
    &--large {
      position: relative;

      .price__amount {
        color: $green-darker;
        font-weight: 900;
        line-height: 1;
        display: inline-flex;
        font-size: 6.4rem;

        @media screen and (min-width: $break-large) {
          font-size: 8rem;
        }
      }

      .price__interval {
        position: absolute;
        bottom: 0;
        color: $gray-dark;
        font-weight: bold;
        display: inline-flex;
        white-space: nowrap;
        padding-left: 0.4rem;
        margin-bottom: 0.4rem;
      }
    }
  }

  &__style {
    &--italic {
      font-style: italic;
    }
  }

  &__text-decoration {
    &--line-through {
      text-decoration: line-through;
    }
  }

  &__weight {
    &--black {
      font-weight: 900;
    }

    &--extra-bold {
      font-weight: 800;
    }

    &--bold {
      font-weight: bold;
    }

    &--normal {
      font-weight: normal;
    }
  }
}
