@use "sass:math";

.menu-content {
  display: flex;
  padding: 0 $padding * 2 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  max-width: $content-max-width;


  &--development {
    &::before {
      content: '';
      position: absolute;
      width: 200%;
      height: 100%;
      transform: translateX(-50%);
      background: repeating-linear-gradient(
        45deg,
        rgba($white, 95%),
        rgba($white, 95%) 20px,
        rgba($blue-lighter, 95%) 20px,
        rgba($blue-lighter, 95%) 40px
      );
      z-index:($z-index-a)-11;
    }

  }


  &__development-text{
  display:none;
  

    @media screen and (min-width: $break-x-large) {
      display: block;  
      position: absolute;
      top: 7rem;   
      left: 5%; 
      background-color: white;  
      padding: $padding;
      border-radius: 4px;  
    }
  
  }

  @media screen and (min-width: $break-x-large) {
    padding: 0 math.div($padding, 2) 2 0;
  }

  @media screen and (min-width: $break-x-large) {
    padding: 0;
  }
}

.menu {
  display: flex;

  @media screen and (min-width: $break-x-large) {
    display: none;
  }

  &__menu {
    position: fixed;
    z-index: $z-index-f;
    width: 100%;
    top: $header-height;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $gray-light;
    background-color: rgba($white, 0.95);
    backdrop-filter: blur(25px);
    height: calc(100% - $header-height);

    &__content {
      overflow-y: scroll;
      padding-bottom: $padding * 10;
    }
  }
}

.menu-desktop {
  & > * {
    display: none;

    @media screen and (min-width: $break-x-large) {
      display: flex;
    }
  }
}
