.trainer-card {
  @include card;

  height: 48rem;
  max-width: 41rem;

  &--slider {
    max-width: none;
    margin: 0;
  }

  & > a {
    display: contents;
  }

  // Full border radius siden bildet ligger fadet bak teksten
  img {
    border-radius: $border-radius;
  }
}
