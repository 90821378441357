.vertical-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &__background-color {
    &--gray {
      background-color: $gray-bg-color;
    }
  }

  &__horizontal-placement {
    &--left {
      align-items: flex-start;
      text-align: left;
    }

    &--center {
      align-items: center;
      text-align: center;
    }

    &--right {
      align-items: flex-end;
      text-align: right;
    }
  }

  &__vertical-placement {
    &--top {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--bottom {
      justify-content: flex-end;
    }

    &--space {
      justify-content: space-between;
    }
  }

  &__height {
    &--full {
      height: 100%;
    }
  }

  &__width {
    &--full {
      width: 100%;
    }
  }
}
