.selling-point {
  width: 50%;
  min-width: 16rem;

  @media screen and (min-width: $break-small) {
    width: 25%;
  }
}

.selling-points-wrapper {
  min-width: $card-max-width;

  @media screen and (min-width: $break-small) {
    max-width: $break-medium;
  }

  @media screen and (min-width: $break-medium) {
    width: $break-large;

    & > * {
      justify-content: center;
    }
  }
}
