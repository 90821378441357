.trainer-grid {
  width: 100%;
  display: grid;
  gap: 2.4rem;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: $break-medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $break-large) {
    grid-template-columns: repeat(3, 1fr);
  }

  &__side-by-side {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;

    @media (min-width: $break-medium) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__single {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}
