.text-block {
  width: 100%;

  &--align-text-horizontally {
    &-center {
      ul,
      ol {
        width: $content-item-min-width;

        @media screen and (min-width: $break-large) {
          width: $content-item-min-width-small;
        }

        @media screen and (min-width: $break-large) {
          width: $content-item-min-width-large;
        }
      }
    }
  }

  /* setter padding på tekst inni container som går i full bredde (på container med bakgrunnsfarge er det allerede padding) */
  .full-width-container:not(.full-width-container--bg-color) > & {
    padding: 0 $left-and-right-inset;
  }
}
