.embedded-video{
    width: 100%;
    align-items: center;
    text-align: center;
    
    &__vid {
        position: relative;
        padding: 56.25% 0 0;

        &__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

