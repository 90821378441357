@use "sass:math";

.select-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .select-adornment {
    position: absolute;
    right: 0.4rem;
    top: math.div($input-height - $icon-small-min-size, 2);
    cursor: pointer;
  }
}

.select {
  @include input;

  color: $gray-dark;

  // special padding for select to make it match input fields
  padding: 0.6rem 1.5rem;

  // width based on width of "måned" field in date picker
  min-width: 10.2rem;
  width: auto;

  &--selected {
    color: $black;
  }

  &--width {
    &--full {
      width: 100%;
    }
  }

  &:disabled {
    @include input-disabled-or-read-only;
  }

  &:focus-visible {
    @include input-focus-style;
  }

  &__option {
    color: $black;

    &--placeholder {
      color: $gray-dark;
    }
  }
}
