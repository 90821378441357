$menu-item-height: $touch-target-size + $step;

.menu-section {
  min-height: $menu-item-height;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: $z-index-f;
  flex-direction: column;
  border-bottom: 1px solid $gray-light;

  &:hover {
    @media screen and (min-width: $break-x-large) {
      background-color: $blue-light;
    }
  }

  @media screen and (min-width: $break-x-large) {
    width: auto;
    border: none;
    height: $header-height;
  }

  &__button {
    min-height: $menu-item-height;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: calc($content-max-width);
    padding: 0 $padding * 4;
    box-sizing: border-box;
    font-size: $font-size;
    font-weight: normal;
    color: $black;
    background-color: transparent;
    text-decoration: none;

    @media screen and (min-width: $break-x-large) {
      padding: 0 $padding - 0.8rem 0 $padding;
      height: $header-height;
    }

    &--selected {
      font-weight: bold;
      color: $blue-bg-color;
      background-color: rgba($blue-light, 0.7);

      @media screen and (min-width: $break-x-large) {
        background-color: transparent;
      }
    }

    &__chevron {
      transition: transform 0.3s ease;
      color: $blue-bg-color;

      &--rotated {
        transform: rotateX(180deg);
      }
    }
  }

  &__content {
    width: 100%;

    @media screen and (min-width: $break-x-large) {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: $header-height - 0.5rem;
      left: -1rem;
      width: fit-content;
      padding-top: $padding;
      padding-bottom: $padding;
      background-color: white;
      box-shadow: $box-shadow;
    }

    & > .menu-item {
      height: unset;
      margin: unset;

      &:first-child {
        border-top: 1px solid $gray-light;
      }

      &:last-child {
        border: none;
      }

      @media screen and (min-width: $break-x-large) {
        &:first-child {
          border: none;
        }
      }

      a {
        padding-left: $padding * 7;
        font-weight: normal;

        @media screen and (min-width: $break-x-large) {
          padding-left: $padding * 4;
          padding-right: $padding * 4;
          white-space: nowrap;
        }
      }

      &--selected {
        a {
          font-weight: bold;
          color: $blue-bg-color;
        }
      }
    }
  }
}
