.icon {
  display: flex;
  min-height: $touch-target-size;
  min-width: $touch-target-size;
  justify-content: center;
  align-items: center;
  position: relative;

  /* default icon size */
  & > * {
    height: 2.4rem;
    width: 2.4rem;
    color: $icon-color;
  }

  nav & {
    cursor: pointer;
  }

  &__color {
    &--green {
      & > * {
        color: $green-darker;
      }
    }

    &--white {
      & > * {
        color: white;
      }
    }

    &--gray {
      & > * {
        color: $gray-dark;
      }
    }

    &--blue {
      & > * {
        color: $blue;
      }
    }
  }

  &__size {
    &--x-small {
      min-height: $icon-small-min-size;
      min-width: $icon-small-min-size;

      & > * {
        height: 1.6rem;
        width: 1.6rem;
      }
    }

    &--small {
      min-height: $icon-small-min-size;
      min-width: $icon-small-min-size;

      & > * {
        height: 2rem;
        width: 2rem;
      }
    }

    &--medium {
      & > * {
        height: 2.4rem;
        width: 2.4rem;
      }
    }

    &--large {
      & > * {
        height: 3.2rem;
        width: 3.2rem;
      }
    }

    &--x-large {
      & > * {
        height: 4.4rem;
        width: 4.4rem;
      }
    }

    &--xx-large {
      & > * {
        height: 6.4rem;
        width: 6.4rem;
      }
    }
  }

  &:not(.alert-icon) .alert-bubble {
    position: absolute;
    right: -0.6rem;
  }

  &--click-through {
    pointer-events: none;
  }

  &--stripped {
    min-height: auto;
    min-width: auto;
  }
}
