/* global styling for details tag */
details {
  width: 100%;

  summary {
    font-size: $font-size-small;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    text-decoration: none;

    &::marker {
      content: "";
    }
  }
}

/* spesifikk styling for Details-komponent */
.details {
  width: 100%;

  summary {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: $font-size;
    text-align: left;
    text-decoration: none;

    /* Safari */
    &::-webkit-details-marker {
      display: none;
    }
  }

  &__summary-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;

    &__prefix {
      width: 4rem;
      min-width: 4rem;

      @media screen and (min-width: $break-small) {
        width: 8rem;
        min-width: 8rem;
      }
    }

    &__content {
      width: 100%;
      max-width: $content-container-width-small;
      display: flex;
      flex-flow: wrap;
    }
  }

  &__children-container {
    &--has-prefix {
      margin-left: 4rem;

      @media screen and (min-width: $break-small) {
        margin-left: 8rem;
      }
    }
  }

  &-wrapper {
    @include span-component-full-width;

    padding: 0 $left-and-right-inset;
    border-bottom: 1px solid $gray-light;
    max-width: $content-container-width-medium;

    @media screen and (min-width: $break-small) {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding: 0;
    }

    &--full-width {
      @include span-component-full-width;

      padding: 0 $left-and-right-inset;
      max-width: none;

      @media screen and (min-width: $break-large) {
        margin-right: 0;
        margin-left: 0;
        width: 100%;
        padding: 0;
        max-width: $content-max-width;
      }
    }

    /* border på toppen virker kun hvis DetailsArea er wrapped rundt Details-komponentene */
    &:first-of-type {
      border-top: 1px solid $gray-light;
    }

    &--hover-bg-color {
      &:hover {
        background-color: $blue-light;
      }

      @media screen and (min-width: $break-large) {
        padding: 0 0 0 0.4rem; /* bittelitt padding i venstre side pga hover-farge */
      }
    }
  }

  &-area {
    display: contents;
  }
}
