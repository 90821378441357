@use "sass:math";

$service-button-width: 6.2rem;
$service-button-height: 7rem;
$service-booked-color: #89fb87;
$service-booked-bg-color: #0c4e0b;
$border-radius-adjusted: $border-radius - 1px; /* adjust to avoid small gap between fill color and border */

.service-booking-button {
  border: 1px solid $gray-darkest;
  border-radius: $border-radius;
  background-color: $white;
  width: $service-button-width;
  height: $service-button-height;
  min-width: $service-button-width;

  @media screen and (min-width: $break-small) {
    width: $service-button-width + $step * 2;
    height: $service-button-height + $step * 2;
  }

  @media screen and (min-width: $break-medium) {
    width: $service-button-width + $step;
    height: $service-button-height + $step;
  }

  &--booked-by-me,
  &--booked-by-me-confirmed {
    background-color: $service-booked-bg-color;
    color: $service-booked-color;
  }

  &--selected {
    background-color: $green;
  }

  &--unavailable {
    background-color: $disabled-bg-color;
    color: $blackish;
    cursor: default;
  }

  &__top,
  &__bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
  }

  &__top {
    border-top-left-radius: $border-radius-adjusted;
    border-top-right-radius: $border-radius-adjusted;

    &--available {
      background-color: $white;
    }

    &--booked-by-me-confirmed {
      position: relative;
      top: $step;

      .icon > * {
        color: $green-lighter;
      }
    }
  }

  &__bottom {
    border-bottom-left-radius: $border-radius-adjusted;
    border-bottom-right-radius: $border-radius-adjusted;

    &--available {
      background-color: $gray-darkest;
      color: $white;
    }

    &--booked-by-me,
    &--booked-by-me-confirmed,
    &--unavailable {
      line-height: 1.2;
    }

    &--booked-by-me,
    &--booked-by-me-confirmed,
    &--unavailable,
    &--selected {
      position: relative;
      top: -(math.div($input-padding, 2));
    }

    &--booked-by-me,
    &--selected {
      .icon > * {
        color: $service-booked-bg-color;
      }
    }
  }
}

.service-booking-button-skeleton {
  width: $service-button-width;
  height: $service-button-height;
}
