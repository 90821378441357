$component-height: 50rem;
$component-top-height: 38%;
$component-middle-height: 21rem;
$component-bottom-height: 20%;
$component-max-width: 40rem;

.news-card {
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  background-color: $fill-color;
  width: 100%;
  height: $component-height !important; /* should not be unset in sidebar */
  max-width: $component-max-width;

  &--block-margin {
    @include block-margin;
  }

  &__heading-and-button {
    padding: $padding * 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: $component-top-height;
    justify-content: space-between;

    h2 {
      font-size: 3.2rem;
      text-align: left;
    }
  }

  
  .media {
    height: $component-middle-height;
  }

  &__text {
    padding: $padding $padding * 2 $padding * 2;
    height: $component-bottom-height;

    p {
      margin-bottom: 0;
    }
  }
}
