.breadcrumbs {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  font-size: $font-size-small;
  margin-bottom: 1.6rem;
  margin-top: 1.2rem;
  width: 100%;

  & > * {
    color: #515151;
    margin-right: 0.4rem;
    white-space: nowrap;
    text-decoration: none;
    margin-bottom: 0.8rem;

    &:not(.icon) {
      padding: 0.4rem 1.2rem;
      background-color: $neutral-bg-color;
      border-radius: 20px;
    }
  }

  &__current {
    color: $black;
  }
}
