$campaign-block-icon-width: 4.8rem;

.campaign-block {
  width: 100%;
  max-width: 44rem;

  .card {
    align-items: flex-start;
    padding: $padding * 2.5;
  }

  ul {
    margin-bottom: 0;
  }

  &__heading {
    width: 100%;
    position: relative;

    &__title {
      width: calc(100% - $campaign-block-icon-width);
      text-align: left;
    }

    &__icon {
      width: $campaign-block-icon-width;
      background-color: transparent;
      position: absolute;
      top: -1.6rem;
      right: -0.8rem;
      content: url("/images/components/campaign_small.png");
    }
  }
}
