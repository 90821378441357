.filter-button {
  height: $filter-button-height;
  width: 100%;
  position: relative;

  &__visible {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    height: $touch-target-size;
    padding: 0.4rem 0.8rem;
    color: $black;
    font-weight: bold;
  }

  &__chevron {
    transition: transform 0.3s ease;
    right: 0.4rem;
    position: absolute;

    &--rotated {
      transform: rotateX(180deg);
    }
  }
}

.filter-button-wrapper {
  min-width: 16.8rem; /* bredden her må tilpasses break-point og filterraden under */

  &:not(:last-of-type) {
    padding-right: 0.8rem;
  }
}
