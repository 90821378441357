@use "sass:math";

/* TEMP styling til designteamet får ordnet */

.feedback {
  padding: 0.8rem 1.2rem;
  border-radius: math.div($border-radius, 4);
  background-color: #edf7ed;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: green;

  &--error {
    background-color: #fdeded;
    color: $red;
  }

  &:empty {
    display: none;
  }
}
