$sidebar-content-width: 44rem;

.sidebar-container {
  display: none;

  &--show-on-mobile {
    display: flex;
    margin-top: 4rem;
    width: 100%;
    max-width: $content-container-width-large;
  }

  @media screen and (min-width: $break-x-large) {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-end;
    padding-top: 1.1rem; /* really specific */

    & > * {
      width: $sidebar-content-width !important;
      max-width: $sidebar-content-width !important;
      height: unset !important;
    }
  }
}
