@use "sass:math";

$booking-button-width: 10.8rem;
$booking-button-width-small: 12rem;
$booking-button-width-medium: 16rem;
$booking-button-padding: 0.8rem;
$image-width-large: 22rem;
$image-height-large: 13rem;
$booking-button-border: 1px solid #00000040;
$booking-button-border-radius: math.div($border-radius, 2);

.booking-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: $box-shadow;
  border: $booking-button-border;
  border-radius: $booking-button-border-radius;
  background-color: $fill-color;
  min-width: $content-item-min-width;

  @media screen and (min-width: $break-small) {
    min-width: $content-item-min-width-small;
  }

  @media screen and (min-width: $break-large) {
    min-width: $content-item-min-width-large;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* do not change order of these */
  &--booked {
    background-color: $green-bg-color;
  }

  &--waiting-list {
    background-color: $yellow-bg-color;
  }

  /* booking-button loading skeleton */
  &--loading {
    min-height: 9.2rem;
    padding: $booking-button-padding;
    border: none;
    box-shadow: none;
  }

  &__image {
    display: none;

    @media screen and (min-width: $break-large) {
      display: flex;
      width: $image-width-large;
      height: $image-height-large;
    }

    &--fill {
      @media screen and (min-width: $break-large) {
        height: auto;
      }
    }

    .image img {
      object-fit: cover; /* set image to cover available space, will be cropped if too big */
      border-bottom-left-radius: $booking-button-border-radius;
      border-top-left-radius: $booking-button-border-radius;

      .booking-button--show-message & {
        border-bottom-left-radius: 0;
      }
    }
  }

  &__main {
    display: flex;
    flex-flow: nowrap;
    width: 100%;
    min-height: $touch-target-size * 2;

    @media screen and (min-width: $break-large) {
      width: calc(100% - $image-width-large);
    }

    &__left {
      width: calc(100% - $booking-button-width);
      display: flex;
      flex-direction: column;
      padding: $booking-button-padding;

      @media screen and (min-width: $break-small) {
        width: calc(100% - $booking-button-width-small);
      }

      @media screen and (min-width: $break-medium) {
        width: calc(100% - $booking-button-width-medium);
        padding-left: $booking-button-padding * 2;
      }

      /* span full width if there are no buttons on the right side */
      &--full-width {
        width: 100%;

        @media screen and (min-width: $break-small) {
          width: 100%;
        }

        @media screen and (min-width: $break-medium) {
          width: 100%;
        }
      }

      h2 {
        margin-bottom: 0;
        font-size: $font-size;
        font-weight: bold;
      }
    }

    &__right {
      width: $booking-button-width;
      display: flex;
      flex-direction: column;
      background-color: $blue; /* blue button for non-booked */

      @media screen and (min-width: $break-small) {
        width: $booking-button-width-small;
      }

      @media screen and (min-width: $break-medium) {
        width: $booking-button-width-medium;
      }

      /* do not change order of these */
      &--unavailable-online {
        background-color: $blue-lighter; /* lighter blue button when booking is unavailable online  */

        .button {
          &__visible {
            background-color: $blue-lighter;
          }
        }
      }

      &--booked {
        background-color: $green; /* green button for booked */
      }

      &--waiting-list {
        background-color: $yellow; /* yellow button for waiting-list */
      }

      .button {
        height: 100%;
        min-width: auto;
        width: 100%;

        &__visible {
          white-space: inherit;
          height: 100%;
          border-radius: $booking-button-border-radius;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          font-size: $font-size;
          font-weight: 900;

          .booking-button--show-message & {
            border-bottom-right-radius: 0;
          }

          .price {
            font-weight: 700;
          }
        }
      }
    }

    .heading {
      text-align: left;
    }

    .text--truncate {
      @media screen and (min-width: $break-small) {
        max-width: 36rem;
      }

      @media screen and (min-width: $break-medium) {
        max-width: 52rem;
      }

      @media screen and (min-width: $break-large) {
        max-width: 36rem;
      }
    }
  }

  &__message {
    width: 100%;
    min-width: $content-item-min-width;
    border-bottom-left-radius: $booking-button-border-radius;
    border-bottom-right-radius: $booking-button-border-radius;
    border-top: $booking-button-border;
    min-height: $touch-target-size - $step;
    padding: 0 $booking-button-padding;

    @media screen and (min-width: $break-medium) {
      padding-left: $booking-button-padding * 2;
    }

    .info-circle-icon {
      min-width: auto;
      min-height: auto;
      margin-right: $step;
    }

    & > button {
      display: contents;
    }

    &__link{
      text-decoration: underline;

    }
  }

  &-top-image {
    display: flex;
    width: 100%;
    box-shadow: $box-shadow;
    border-top: $booking-button-border;
    border-right: $booking-button-border;
    border-left: $booking-button-border;
    border-top-left-radius: $booking-button-border-radius;
    border-top-right-radius: $booking-button-border-radius;

    @media screen and (min-width: $break-large) {
      display: none;
    }
  }
}
