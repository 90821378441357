.sticky-bottom-button {
  position: sticky;
  background-color: rgba($white, 0.95);
  z-index: $z-index-a;
  bottom: 0;
  padding: $padding * 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include span-component-full-width;

  @media screen and (min-width: $break-large) {
    width: 100%;
    margin-left: 0;
    padding-bottom: $padding * 4;
  }

  &--horizontal-placement {
    &--left {
      align-items: flex-start;

      @media screen and (min-width: $break-large) {
        padding-left: 0;
      }
    }
  }
}
