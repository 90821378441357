.content-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &--padding {
    padding: $left-and-right-inset;
  }

  &__horizontal-placement {
    &--center {
      align-items: center;
      text-align: center;
    }

    &--left {
      align-items: flex-start;
      text-align: left;
    }
  }

  &__width {
    &--xx-small {
      max-width: $content-container-width-xx-small;
    }

    &--x-small {
      max-width: $content-container-width-x-small;
    }

    &--small {
      max-width: $content-container-width-small;
    }

    &--medium {
      max-width: $content-container-width-medium;
    }

    &--large {
      max-width: $content-container-width-large;
    }

    &--x-large {
      max-width: $content-container-width-x-large;
    }
  }
}
