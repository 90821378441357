.search-and-select {
  input {
    font-size: $font-size;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  input:focus {
    outline: none;
  }

  &--input-adornment {
    z-index: $z-index-b;
    position: absolute;
    top: 1rem;

    &--left {
      left: 0.8rem;
    }

    &--right {
      background-color: transparent;
      cursor: pointer;
      right: 0.8rem;
      padding-left: 0.8rem;
      padding-right: 0.4rem;
      border-left: 1px solid $black;
      color: $gray-dark;
    }
  }

  &__results {
    position: absolute;
    top: 100%;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: $input-border-radius;
    box-shadow: $box-shadow;
    width: 100%;
    z-index: $z-index-b;
  }

  &__result {
    display: contents;

    button {
      display: block;
      appearance: none;
      width: 100%;

      @media screen and (min-width: $break-medium) {
        &:hover {
          .search-hit--title {
            font-weight: bold;
            color: $blue-bg-color;
          }

          background-color: $blue-light;
        }
      }

      &:focus-visible {
        outline: none;
        background-color: $blue-light;

        .search-and-select__result--title {
          font-weight: bold;
          color: $blue-bg-color;
        }
      }

      &:focus {
        outline: none;
        background-color: $blue-light;

        .search-and-select__result--title {
          font-weight: bold;
          color: $blue-bg-color;
        }
      }
    }
  }
}