.grid-container {
  width: 100%;
  display: grid;

  &__column-layout {
    &--three_columns_responsive {
      grid-template-columns: repeat(1, 1fr);

      @media (min-width: $break-medium) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (min-width: $break-large) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &--two_columns_responsive {
      grid-template-columns: repeat(1, 1fr);

      @media (min-width: $break-medium) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--two_columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &--five_columns{
      grid-template-columns: repeat(5, 1fr);
    }
    
    &--one_column {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__horizontal-placement {
    &--center {
      text-align: center;
      justify-content: center;
    }

    &--left {
      text-align: left;
      justify-content: left;
    }
  }

  &__column-spacing {
    &--xx-small {
      column-gap: 0.4rem;
    }

    &--x-small {
      column-gap: 0.8rem;
    }

    &--small {
      column-gap: 1.6rem;
    }

    &--medium {
      column-gap: 2.4rem;
    }

    &--large {
      column-gap: 4rem;
    }

    &--x-large {
      column-gap: 6.4rem;
    }

    &--xx-large {
      column-gap: 8rem;
    }
  }

  &__row-spacing {
    &--xx-small {
      row-gap: 0.4rem;
    }

    &--x-small {
      row-gap: 0.8rem;
    }

    &--small {
      row-gap: 1.6rem;
    }

    &--medium {
      row-gap: 2.4rem;
    }

    &--large {
      row-gap: 4rem;
    }

    &--x-large {
      row-gap: 6.4rem;
    }

    &--xx-large {
      row-gap: 8rem;
    }
  }
}
