.flex-flow-container {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $break-small) {
    flex-flow: row wrap;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }

  &__horizontal-placement {
    &--space {
      @media screen and (min-width: $break-small) {
        justify-content: space-between;
      }
    }
  }

  &__vertical-placement {
    &--bottom {
      @media screen and (min-width: $break-small) {
        align-items: flex-end;
      }
    }

    &--center {
      @media screen and (min-width: $break-small) {
        align-items: center;
      }
    }

    &--top {
      align-items: flex-start;
    }
  }

  &__width {
    &--full {
      width: 100%;
    }
  }
}
