.full-screen-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-d;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}