.countdown-wrapper {
  min-width: $card-max-width;

  @media screen and (min-width: $break-small) {
    min-width: $card-max-width-desktop;
  }

  &--fullWidth {
    width: 100vw;
  }
}

.countdown-contents {
  display: inline-block;

  h2 {
    font-weight: bold;
  }
}

.countdown-unit {
  width: 8rem;
  margin-right: $step;

  @media screen and (min-width: $break-small) {
    margin-right: 4.8rem;
  }
}

.countdown-unit:last-of-type {
  margin-right: 0;
}
