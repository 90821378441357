.PieChart {
  box-shadow: 0 0 3px 0 #f8f8f8;
  border-radius: 1rem;
  padding: 2rem;
  background-color: #f8f8f8;

  .recharts-label {
    fill: $white;
  }
}
