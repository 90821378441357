@use "sass:math";

$padding-content: $padding * 2;
$bottom-baseline: -4rem;
$text-shadow: 0 2px 30px rgb(0 0 0 / 80%);

.media-card {
  position: relative;
  display: flex;
  flex-direction: column;
  color: $white;
  width: 100%;

  &--vertical-align {
    &--baseline {
      margin-bottom: -$padding-content - $bottom-baseline;
    }
  }

  &--text-theme {
    &--dark {
      color: $black;
    }
  }

  &--block-margin {
    @include block-margin;

    height: auto;

    @media screen and (min-width: $break-large) {
      /* using calc to allow comparison between viewport height and min-height(rem) */
      max-width: calc((50% - $block-margin) / 2);
    }

    @media screen and (min-width: $break-x-large) {
      max-width: calc(50% - $block-margin);
    }

    &:first-of-type {
      @media screen and (min-width: $break-large) {
        margin-left: 0;
        margin-right:  math.div($block-margin, 2);
      }

      @media screen and (min-width: $break-x-large) {
        margin-right: $block-margin;
      }
    }

    &:last-of-type {
      @media screen and (min-width: $break-large) {
        margin-right: 0;
        margin-left:  math.div($block-margin, 2);
      }

      @media screen and (min-width: $break-x-large) {
        margin-left: $block-margin;
      }
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: $padding-content;

    &--horizontal-align {
      &--left {
        width: auto;
        align-self: flex-start;
      }

      &--right {
        width: auto;
        align-self: flex-end;
      }
    }

    &--vertical-align {
      &--top {
        top: 0;
        transform: translateY(0%);
      }

      &--bottom {
        top: auto;
        bottom: 0;
        transform: translateY(0%);
      }

      &--baseline {
        top: auto;
        bottom: $bottom-baseline;
        transform: translateY(0%);
      }
    }

    h2 {
      font-size: 4rem;
      text-align: center;
      text-shadow: $text-shadow;

      @media screen and (min-width: $break-large) {
        font-size: 8rem;
      }
    }

    &--heading-size {
      &--small {
        h2 {
          font-size: 2.8rem;

          @media screen and (min-width: $break-large) {
            font-size: 6.4rem;
          }
        }
      }
    }

    &--slider,
    &--block-margin {
      h2 {
        @media screen and (min-width: $break-large) {
          font-size: 4rem;
        }
      }
    }
  }
}
