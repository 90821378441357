.rich-text-json-renderer {
  &-override {
    &__color {
      &--gray {
        // Need to select on this specificity level to override the global styling
        li,
        a {
          color: $gray-dark;
        }
      }

      &--red {
        li,
        a {
          color: $red;
        }
      }

      &--light {
        li,
        a {
          color: $white;
        }
      }
    }
  }
}
