.list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  li {
    flex-basis: auto;
    min-width: $content-item-min-width;

    @media screen and (min-width: $break-small) {
      flex-basis: calc(50% - 2.4rem);
      min-width: auto;
      margin-right: 1.2rem;
    }
  }

  &--three-columns {
    ul {
      justify-content: start;
    }

    li {
      @media screen and (min-width: $break-large) {
        flex-basis: calc(33.333% - $padding * 2);
        margin-right: $padding * 2;
      }
    }
  }
}
