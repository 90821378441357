$circle-size: 2.2rem;
$max-width: $content-container-width-x-small; /* change this if there are more/fewer steps */

.step-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: $font-size-small;
  position: relative;
  width: 100%;
  max-width: $max-width;

  &__steps-and-button {
    display: flex;
    width: 100%;
    flex-flow: nowrap;
    padding-bottom: $padding;

    &__steps {
      display: flex;
      width: 100%;
      margin-right: $padding;

      &--no-button {
        margin-right: 0;
      }

      &__step {
        display: flex;
        align-items: center;
        width: $circle-size;

        &__line {
          background-color: $gray-darker;
          height: 1px;
          width: calc(100% - $circle-size);
        }

        &:last-of-type {
          .step-indicator__steps__step__line {
            display: none;
          }
        }
      }
    }
  }

  &__circle {
    border: 1px solid #404040;
    border-radius: 50%;
    width: $circle-size;
    height: $circle-size;
    display: flex;
    justify-content: center;
    align-items: center;

    &--active {
      background-color: $gray-darker;
      color: #fff;
    }
  }

  &__information {
    background-color: $white;
    padding: $padding;
    border-radius: $border-radius;
    height: auto;
    display: flex;
    flex-direction: column;
    box-shadow: $box-shadow;
    width: 100%;
    max-width: $max-width;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
