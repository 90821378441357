.swiper {
  display: block;

  &--full-width {
    margin-left: -$left-and-right-inset; /* swiper skal spanne full bredde på mobil */
    margin-right: -$left-and-right-inset;
    max-width: calc(100% + $left-and-right-inset * 2) !important;

    @media screen and (min-width: $break-large) {
      margin-left: 0;
      margin-right: 0;
      max-width: 100% !important;
    }
  }

  &__arrow-button {
    &--prev {
      width: auto !important;
      height: auto !important;
      background-color: #fffc !important;
      opacity: 1 !important;
      border-radius: 50% !important;
      padding: 0.4rem !important;
      box-shadow: $box-shadow;
      left: 0.4rem !important;
    }

    &--next {
      width: auto !important;
      height: auto !important;
      background-color: #fffc !important;
      opacity: 1 !important;
      border-radius: 50% !important;
      padding: 0.4rem !important;
      box-shadow: $box-shadow;
      right: 0.4rem !important;
    }

    &--on-top {
      top: 0 !important;
    }

    &:disabled {
      display: none;
    }
  }
}

li.splide__slide {
  padding: 0 0 $padding;
}

.splide__pagination {
  bottom: -2.5rem !important;
  color: $blue-bg-color !important;

  li {
    padding: 0 $step;
  }
}

.splide__pagination__page {
  background: $blue-bg-color !important;
}
