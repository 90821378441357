.vertical-spacer {
  padding-right: 1.6rem; /* small is default */

  &__size {
    &--xx-small {
      padding-right: 0.4rem;
    }

    &--x-small {
      padding-right: 0.8rem;
    }

    &--small {
      padding-right: 1.6rem; /* default */
    }

    &--medium {
      padding-right: 2.4rem;
    }

    &--large {
      padding-right: 4rem;
    }

    &--x-large {
      padding-right: 6.4rem;
    }
  }
}
