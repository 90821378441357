.article-overview {
  &__featured {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: auto;

    @media (min-width: $break-medium) {
      grid-template-columns: 1fr 1fr;
      height: 44rem;
    }

    @media (min-width: $break-large) {
      grid-template-columns: 3fr 2fr;
    }

    @media (min-width: $break-x-large) {
      grid-template-columns: 7fr 3fr;
    }

    &__text {
      background-color: $black;
      padding: 0 $left-and-right-inset * 2 $left-and-right-inset;

      &__text-body {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 12;
        line-clamp: 12;
      }
    }
  }

  &__tag-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 1.6rem;
  }
}
