.pt-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 $padding * 2;
  background-color: rgba($white, 0.9);
  border-radius: 0 0 $border-radius $border-radius;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--solid-bg-color {
    background-color: rgba($white, 1);

    @media screen and (min-width: $break-large) {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      border-radius: 0;
      padding: 0;
    }
  }
}
