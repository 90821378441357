@use "sass:math";

$adjustment: 6px;

.filter-wrapper {
  @include sticky-top;

  background: linear-gradient(
    to top,
    transparent,
    transparent $filter-button-spacer,
    $white $filter-button-spacer,
    $white
  );
  padding-bottom: 0;
  padding-top: 2rem;
  margin-top: -1.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% + $padding * 4); /* span all across to the sides */
  max-width: $content-max-width;

  @media screen and (min-width: $break-large) {
    width: calc(100% + $adjustment); /* adjust to cover box-shadow on elements below */
  }

  &--show-sub-filter {
    background: linear-gradient(to top, $white, $white);
  }

  /* first-child is ref element */
  & > *:first-child {
    display: contents;

    @media screen and (min-width: $break-large) {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
    }
  }
}
