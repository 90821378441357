.button__visible {
  background-color: transparent;
  padding: $input-padding;
  border-radius: $border-radius;
  font-size: $button-font-size;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $black;
  white-space: nowrap;

  &--size {
    &--big {
      min-height: $touch-target-size + $padding;
      width: 100%;
    }
  }

  &--width {
    &--full {
      width: 100%;
    }
  }

  &--wrap {
    white-space: normal;
  }

  &--primary,
  &--primary-light {
    &__visible {
      min-width: 10rem;
      background-color: $green;
      color: $gray-darkest;
      padding: $button-padding;
      box-shadow: $box-shadow;
    }
  }

  &--primary-light {
    &__visible {
      background-color: $green-lighter;
    }
  }

  &--secondary,
  &--secondary-light {
    &__visible {
      min-width: 10rem;
      background-color: $blue;
      color: $black;
      padding: $button-padding;
      box-shadow: $box-shadow;
    }
  }

  &--secondary-light {
    &__visible {
      background-color: $blue-lighter;
    }
  }

  &--tertiary {
    &__visible {
      min-width: 10rem;
      background-color: $yellow;
      color: $black;
      padding: $button-padding;
      box-shadow: $box-shadow;
    }
  }

  &--neutral {
    &__visible {
      min-width: 10rem;
      background-color: $fill-color;
      color: $black;
      padding: $button-padding;
      box-shadow: $box-shadow;
    }
  }
}
