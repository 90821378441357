@use "sass:math";

.filter-new-day-heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.8rem;

  > * {
    background-color: $gray-light;
    padding: 0.8rem 1.2rem;
    border-radius: math.div($border-radius, 2);
  }
}
