.gift-card {
  @include card;

  min-height: 17rem;
  padding: $padding * 2;

  &__product__icon {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $gray-light;
    padding: 0.5rem;
    border-radius: 0 $border-radius 0 1.5rem;
  }

  &__text-area {
    height: 5rem;
  }
}
