.rich-text-block {
  width: 100%;

  &--text-theme {
    &-dark {
      color: $black;
    }

    &-light {
      color: $white;
    }
  }
}
