.flex-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  direction: ltr;

  @media screen and (min-width: $break-large) {
    flex-direction: row;
  }

  &__media {
    display: flex;
    flex: 1;

    @include span-component-full-width($adjust-margin: false);

    margin: 0 (-$left-and-right-inset) $padding; /* bilde skal spanne full bredde på mobil */

    @media screen and (min-width: $break-large) {
      margin: 0;
      width: 100%;
    }

    & > * {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;

    @media screen and (min-width: $break-large) {
      padding-left: $padding * 2;
    }

    @media screen and (min-width: $break-x-large) {
      padding-left: $padding * 4;
    }

    .heading {
      text-align: left;
    }
  }

  &--direction {
    &-mediaOnTheRight {
      @media screen and (min-width: $break-large) {
        direction: rtl;
      }

      .flex-card__content {
        @media screen and (min-width: $break-large) {
          padding-left: 0;
          padding-right: $padding * 2;
          align-items: flex-end;
        }

        @media screen and (min-width: $break-x-large) {
          padding-right: $padding * 4;
        }

        & * {
          direction: ltr;
        }
      }
    }
  }

  &--text-theme {
    &-dark {
      color: $black;
    }

    &-light {
      color: $white;
    }
  }

  &--sidebar {
    flex-direction: column;

    .flex-card {
      &__media {
        margin-bottom: $padding;
      }
    }
  }
}
