$card-height: 44rem;

.event-card {
  @include card;

  &__media {
    @include card-media;
  }

  &__content {
    height: calc($card-height - $card-media-height);
    padding: $padding $padding * 1.5 $padding * 1.5;

    @media screen and (min-width: $break-large) {
      height: calc($card-height - $card-media-height-desktop);
    }

    &__times--gradient {
      mask-image: linear-gradient(to top, transparent, $white 50%);
      height: 100%;
      max-height: 4.4rem;

      @media screen and (min-width: $break-large) {
        max-height: 6.4rem;
      }
    }

    .button {
      position: absolute;
      bottom: 0;
    }
  }
}
