$button-padding: $input-padding * 1.5 $input-padding * 2;

.button {
  min-height: $touch-target-size;
  border: none;
  cursor: pointer;
  appearance: none;
  background-color: transparent;
  width: fit-content;
  line-height: 1.2;

  &--size {
    &--big {
      min-height: $touch-target-size + $padding;
      width: 100%;
      max-width: $content-item-min-width-small;
    }
  }

  &--width {
    &--full {
      width: 100%;
      max-width: $content-item-min-width-small;
    }
  }

  &:focus-visible {
    @include input-focus-style;
  }

  &--disabled {
    .button__visible {
      background-color: $disabled-bg-color;
      border: none;
      color: $gray-darkest;
      cursor: default;
    }
  }

  &--selected {
    .button__visible {
      border: solid $green-darker;
      border-width: 0.2rem;
    }
  }

  .icon {
    min-width: auto;
    min-height: auto;
  }
}
