@use "sass:math";

$component-height: 42rem;
$component-width: 100%;
$component-max-width: 40rem;
$text-shadow: 0 1px 35px rgb(0 0 0 / 80%);

.image-heading-and-text-card {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  width: $component-width;

  @media screen and (min-width: $break-small) {
    max-width: $component-max-width;
    height: $component-height; /* TODO: denne skaper trøbbel hvis det kun er én enkel komponent */
  }

  @media screen and (min-width: $break-x-large) {
    height: $component-height + 4rem;
  }

  .media {
    height: 100%;

    img {
      border-radius: $border-radius;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $padding * 2;
    color: $white;

    & > * {
      align-self: center;
      text-align: center;
    }

    &--horizontal-align {
      &--left {
        align-items: flex-start;

        & > * {
          align-self: flex-start;
          text-align: left;
        }
      }
    }

    &--text-theme {
      &--dark {
        color: $black;
      }
    }

    h2 {
      font-size: 2.8rem;
      text-shadow: $text-shadow;

      @media screen and (min-width: $break-large) {
        font-size: 3.2rem;
      }
    }

    &__text {
      text-align: center;
      text-shadow: $text-shadow;

      & > * {
        text-align: center !important;
      }

      &--horizontal-align {
        &--left {
          text-align: left;

          & > * {
            text-align: left !important;
          }
        }
      }

      &--text-size {
        &--large {
          font-size: $font-size-x-large;
        }
      }

      &--quote {
        font-size: 2rem;
        font-weight: 900;

        &::before {
          content: url("/images/components/quote_white.png");
          line-height: 4.4rem;
        }

        &--text-theme {
          &--dark {
            &::before {
              content: url("/images/components/quote_black.png");
            }
          }
        }
      }
    }
  }

  &-wrapper {
    position: relative;
    width: $component-width;
    text-decoration: none;
  }

  &--block-margin {
    @include block-margin;

    height: auto;
    max-width: none;

    @media screen and (min-width: $break-large) {
      /* using calc to allow comparison between viewport height and min-height(rem) */
      max-width: calc((50% - $block-margin) / 2);
    }

    @media screen and (min-width: $break-x-large) {
      max-width: calc(50% - $block-margin);
    }

    &:first-of-type {
      @media screen and (min-width: $break-large) {
        margin-left: 0;
        margin-right: math.div($block-margin, 2);
      }

      @media screen and (min-width: $break-x-large) {
        margin-right: $block-margin;
      }
    }

    &:last-of-type {
      @media screen and (min-width: $break-large) {
        margin-right: 0;
        margin-left: math.div($block-margin, 2);
      }

      @media screen and (min-width: $break-x-large) {
        margin-left: $block-margin;
      }
    }
  }

  &--full-width {
    /* reset'er når max-width slår inn */
    &:not(.image-heading-and-text-card--slider) {
      @media screen and (max-width: $break-small) {
        @include span-component-full-width;
      }
    }

    &.image-heading-and-text-card--block-margin {
      @media screen and (max-width: $break-small) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &--slider {
    max-width: none;
    height: auto;

    &--full-width {
      border-radius: 0;

      .media {
        img {
          border-radius: 0;
        }
      }
    }
  }
}
