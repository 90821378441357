.textarea {
  @include input;

  min-height: $input-height * 2;
  width: 100%;

  &:focus-visible {
    @include input-focus-style;
  }
}
