.booked-button-raised {
  position: absolute;
  top: -$top-inset * 2.25;
  left: -$padding;
  right: -$padding;
  width: calc(100% + $padding * 2);
  background-color: rgba($neutral-bg-color, 0.9);
  backdrop-filter: blur(3px);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  padding: $padding * 1.5;
}
