.gift-card-wrapping-card {
  @include card;

  padding: $padding;
  height: 22rem;
  align-items: center;
  justify-content: center;

  &--cross {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: $beige-bg-color;
    }

    &::before {
      width: 25px;
      height: 100%;
    }

    &::after {
      width: 100%;
      height: 25px;
    }
  }

  &--content {
    padding: $padding * 2;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    border-radius: 8px;
    z-index: 1;
  }
}
