$menu-item-height: $touch-target-size + $step;

.menu-item {
  min-height: $menu-item-height;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid $gray-light;

  @media screen and (min-width: $break-x-large) {
    width: auto;
    height: $header-height;
    border: none;
  }

  &:first-of-type {
    @media screen and (min-width: $break-x-large) {
      margin-left: $padding * 4;
    }
  }

  &:hover {
    background-color: $blue-light;
  }

  a {
    min-height: $menu-item-height;
    width: 100%;
    padding: $step * 2 $padding * 4;
    box-sizing: border-box;
    color: $black;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: calc($content-max-width);

    @media screen and (min-width: $break-x-large) {
      padding: $padding;
    }
  }

  &--selected {
    a {
      font-weight: bold;
      color: $blue-bg-color;
      background-color: rgba($blue-light, 0.7);

      @media screen and (min-width: $break-x-large) {
        background-color: transparent;
      }
    }
  }
}
