.flex-container {
  display: flex;

  &__horizontal-placement {
    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &--no-wrap {
    flex-flow: nowrap;
  }
}
