$heading-size-1: 2.4rem;
$heading-size-2: 2rem;
$heading-size-3: 1.8rem;
$heading-size-1-large: 3.2rem;
$heading-size-2-large: $heading-size-1;
$heading-size-3-large: $heading-size-2;

h1 {
  font-size: $heading-size-1;
  margin-bottom: 1.6rem;
  font-weight: 900;

  @media screen and (min-width: $break-large) {
    font-size: $heading-size-1-large;
    margin-bottom: 2.4rem;
  }
}

h2 {
  font-size: $heading-size-2;
  margin-bottom: 0.8rem;
  font-weight: 900;
  line-height: 1.3;

  @media screen and (min-width: $break-large) {
    font-size: $heading-size-2-large;
  }
}

h3 {
  font-size: $heading-size-3;
  margin-bottom: 1.2rem;
  font-weight: bold;
  line-height: 1.3;

  @media screen and (min-width: $break-large) {
    font-size: $heading-size-3-large;
  }
}

.heading {
  &__color {
    &--dark {
      color: $black;
    }

    &--light {
      color: white;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  &--loading {
    h1 {
      min-height: $heading-size-1;
    }

    h2 {
      min-height: $heading-size-2;
    }

    h3 {
      min-height: $heading-size-3;
    }
  }
}
