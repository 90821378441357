.card {
  @include card;

  padding: $padding;

  &--background-color {
    background-color: $fill-color;
  }

  &__horizontal-placement {
    &--left {
      align-items: flex-start;
    }

    &--center {
      align-items: center;
    }

    &--right {
      align-items: flex-end;
    }
  }

  &__height {
    &--full {
      height: 100%;
    }
  }

  &__width {
    &--full {
      width: 100%;
    }
  }
}
