.big-colored-text {
  color: $blue;
  font-weight: 900;
  line-height: 1;
  display: inline-flex;
  font-size: 9.6rem;

  & > * {
    color: $blue;
    font-size: 9.6rem;
  }

  &__color {
    &--green {
      color: $green-darker;

      & > * {
        color: $green-darker;
      }
    }
  }
}
