.list-with-checks {
  ul {
    @include unordered-list-with-green-checks;
  }

  &--row {
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
      @include unordered-list-row-with-green-checks-on-top;
    }
  }
}
