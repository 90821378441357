@use "sass:math";

.hide-or-show-filters-button {
  border: 1px solid $gray-light;
  padding: math.div($padding, 2);
  width: 8rem;
  min-width: 8rem;
  font-size: $font-size-small;
  border-radius: math.div($border-radius, 2);
}
