.main-content {
  padding: $top-inset $left-and-right-inset $padding * 8;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: calc($content-item-min-width + $padding * 4);

  @media screen and (min-width: $break-large) {
    padding: $padding * 2 $padding $padding * 8;
  }

  @media screen and (min-width: $break-x-large) {
    padding: $padding * 2 0 $padding * 8;
  }
}
