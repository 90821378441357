.media {
  position: relative;
  height: 100%;

  &__width {
    &--full {
      width: 100vw;
    }
  }
}
