.link-card {
  @include card;

  cursor: pointer;
  width: 100%;
  background-color: $blue;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: $padding * 2;
  text-decoration: none;
  color: $black;
  min-width: 5rem;

  &--big-on-desktop {
    @media screen and (min-width: $break-large) {
      height: 13rem;
    }
  }

  &__background-color {
    &--neutral {
      background-color: $neutral-bg-color;
    }
  }

  .heading {
    h2 {
      font-size: 2rem;
      display: flex;
      align-items: center;
    }

    > * {
      display: flex;
      flex-flow: nowrap;
      align-items: flex-start;
      margin: 0;
    }
  }

  &:empty {
    display: none;

    @media screen and (min-width: $break-medium) {
      display: flex;
      visibility: hidden;
    }
  }
}
